<template>
  <div class="index">
    <Back2Top />
    <navHeader />
    <main>
      <router-view />
    </main>
    <footer v-if="$route.meta.type === 'default'">
      <section class="content">
        <div class="top">
          <div class="left">
            <span class='cursor-pointer' @click="toPage('/financialService')">科技金融服务</span>
            <span class='cursor-pointer' @click="toPage('/bigData')">产品与数据</span>
            <span class='cursor-pointer' @click="toPage('/aboutUs')">关于中知数通</span>
            <!-- <span class='cursor-pointer' @click="toPage('/media')">新闻与活动</span> -->
          </div>
          <div class="right">
            <div v-for="qrcode in codeList" :key="qrcode.src" class="code-item">
              <img class="img-box" width="95" :src="qrcode.src" />
              <span>{{qrcode.text}}</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          版权所有：中知数通（北京）信息技术有限公司&nbsp;&nbsp;&nbsp;&nbsp;版权所有，翻印必究&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;<span @click="jumpLink('icp')">备案号：京ICP备2021036050号</span>&nbsp;&nbsp;&nbsp;&nbsp;<span
            @click="jumpLink('gongan')">京公网安备 11010202010013号</span>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
import Back2Top from '../components/back2Top.vue'
import navHeader from '../components/navHeader.vue'
import { mapState } from 'vuex'

export default {
  name: 'Index',
  components: { Back2Top, navHeader },
  data() {
    return {
      codeList: [
        // { src: 'https://images.zzt.com.cn/ox/2022/04/26/concatc1.png', text: '扫码联系客服一' },
        { src: 'https://images.zzt.com.cn/ox/2022/04/26/concatc2.png', text: '扫码联系客服' },
        { src: 'https://images.zzt.com.cn/ox/2022/12/06/weixin.png', text: '微信公众号' }
      ]
    }
  },
  computed: {
    ...mapState(['isLogin'])
  },
  methods: {
    toPage(path) {
      this.$router.push(path)
      window.scrollTo(0, 0, 'smooth')
    },
    jumpLink(type) {
      let url = type == 'icp' ? 'https://beian.miit.gov.cn' : 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202010013'
      window.open(url)
    }
  },
  async mounted() {
    const queryToken = new URL(location.href).searchParams.get('token')
    const localStorageToken = localStorage.getItem('ZZT_SSO_TOKEN')
    const token = queryToken || localStorageToken
    // 前置条件：当前状态是没有登录的才进行登录尝试
    if (!this.isLogin && this.$route.name !== 'Login') {
      if (token) {
        this.$store.commit('setToken', token)
        await this.$store.dispatch('getUserInfo')
        // 重定向，去掉token参数
        if (new URL(location.href).searchParams.get('token')) {
          location.replace(location.href.split('?token')[0])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  background-color: $bg-color;
  img {
    transition: width 0.5s;
  }
  main {
    margin-top: $header-height;
    overflow: hidden;
    min-height: calc(100vh - $header-height - Translate(330px));
  }
  footer {
    background: #2e354d;
    height: Translate(330px);
    .content {
      display: flex;
      flex-direction: column;
      width: $container-width;
      height: 100%;
      margin: 0 auto;
      color: #fff;
      .top {
        display: flex;
        justify-content: space-between;
        text-align: left;
        height: 640px;
        box-sizing: border-box;
        .left {
          font-size: 42.21px;
          font-weight: 500;
          padding-top: 206px;
          .cursor-pointer {
            &:hover {
              color: #4088eb;
            }
          }
          span {
            display: inline-block;
            padding-right: 112px;
          }
        }
        .right {
          display: flex;
          padding-top: 117px;
          .code-item {
            margin-left: 42px;
            text-align: center;
            .img-box {
              padding: 10px;
              display: block;
              background-color: #fff;
              margin-bottom: 28px;
            }
            span {
              font-size: 28px;
              color: #999999;
            }
          }
        }
      }
      .bottom {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        font-size: 33px;
        color: #999999;
        border-top: 1px solid #585d71;
      }
    }
  }
}
</style>
