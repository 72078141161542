<template>
  <div class="back-2-top" >
    <img class="eye-gif" src="https://images.zzt.com.cn/ox/2022/12/08/robot_eyes.gif" width="14">
    <div class="icon-box">
      <div class="icon icon1">
        <img id="kefu" src="https://images.zzt.com.cn/ox/2022/12/07/kufu.png" width="20">
      </div>
      <div class="icon icon2">
        <img id="wechat" src="https://images.zzt.com.cn/ox/2022/12/07/wechat.png" width="20">
      </div>
      <div class="icon icon3">
        <img @click="toTop" src="https://images.zzt.com.cn/ox/2022/12/07/back2top.png" width="20">
      </div>
    </div>
    <div id="kehu-tippy">
      <!-- <div style="margin-right: 20px;">
        <img src='https://images.zzt.com.cn/ox/2022/04/26/concatc1.png' width='80'/>
        <p>微信咨询客服一</p>
      </div> -->
      <div>
        <img src='https://images.zzt.com.cn/ox/2022/04/26/concatc2.png' width='80'/>
        <p class="img-text">微信咨询客服</p>
      </div>
    </div>
    <div id="wechat-tippy" >
      <img src='https://images.zzt.com.cn/gnW/2024/04/26/wechat-code.png' width='80'/>
      <p class="img-text">深眸小程序</p>
    </div>
  </div>
</template>
<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export default {
  methods: {
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted () {
    const kehuTippy = document.getElementById('kehu-tippy')
    tippy('#kefu', {
      content: kehuTippy,
      allowHTML: true,
      placement: 'left',
      theme: 'light'
    })
    const wechatEle = document.getElementById('wechat-tippy')
    tippy('#wechat', {
      content: wechatEle,
      allowHTML: true,
      placement: 'left',
      theme: 'light'
    })
  }
}
</script>
<style lang='scss' scoped>
.back-2-top {
  z-index: 20;
  position: fixed;
  right: Translate(34px);
  bottom: 20vh;
  width: Translate(52px);
  height: Translate(192px);
  background: url(https://images.zzt.com.cn/ox/2022/12/08/robot_bg.png) no-repeat;
  background-size: contain;
  .eye-gif {
    position: absolute;
    top: Translate(29px);
    left: Translate(19px);
  }
  .icon-box {
    position: absolute;
    width: 100%;
    top: Translate(64px);
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: Translate(30px);
      height: Translate(30px);
      border-radius: Translate(50px);
      margin-bottom: Translate(10px);
      cursor: pointer;
      &:hover {
        background-color: rgba(#fff, .1);
      }
    }
  }
}

#kehu-tippy {
  text-align: center;
  padding: Translate(20px);
  display: flex;
  img {
    margin-bottom: Translate(10px);
  }
}
#wechat-tippy {
  text-align: center;
  padding: Translate(16px) Translate(40px);
  img {
    margin-bottom: Translate(10px);
  }
}
.img-text{
  font-size: Translate(12px);
}
</style>
<style lang="scss">
#tippy {
  &-1, &-2 {
    margin-right: 50px !important;
  }
}
</style>
