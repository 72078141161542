<template>
  <div>
    <el-dialog title="联系客服" :visible.sync="dialogVisible" width="590px" class="custom_dialog" append-to-body>
      <div class="content">
        <img src="https://images.zzt.com.cn/ox/2022/04/26/concatc2.png">
        <p>扫码联系客服</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CustomerServiceDialog',
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    showDialog(state) {
      this.dialogVisible = state
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: Translate(140px);
    height: Translate(140px);
  }
  p {
    font-size: Translate(14px);
    color: #333333;
    margin-top: Translate(12px);
  }
}
</style>